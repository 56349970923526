import Layout from "components/layout";
import React from "react";
import Houston from "components/locationIndexPages/Houston/index";
import SEO from "components/seo";

function index() {
  const title = "Houston Translation Services - Translation Agency";
  const description =
    "Searching for translation services in Houston? Tomedes provides translation, localization, and interpretation services in 120+ languages with rapid delivery and 24/7 support.";
  const keywords = "Translation Services in Houston";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug="/locations/houston-translation-services"
      />
      <Houston />
    </Layout>
  );
}

export default index;
